<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("Product management") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchFunc="all_products"
          :searchText="$t('ARTICLE_SEARCH')"
          :showFiltre="true"
          @returnValue="updateSearch($event)"
        ></searchBar>
        <!-- --------------------------------------------------filtre modale------------------------------------------------------------------- -->
        <b-modal id="entrepriseModal" ref="entrepriseModal">
          <template #modal-header="{ close }">
            <h5>{{ $t("FILTRE") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>

          <form @submit.prevent="hideModal('entrepriseModal')">
            <div class="center">
              <b-form-group :label="$t('Catégories')">
                <searchInput
                  :list="computedAllArticle"
                  :loader="getLoadingcategorieArticle"
                  label="lib"
                  :selected="filtre_categorie"
                  :multiple="true"
                  :limit="2"
                  :searchFunc="allcategorieArticle"
                  @searchfilter="selectCategorie($event)"
                  :placeholder="$t('CATEGORIES')"
                ></searchInput>
              </b-form-group>
            </div>
          </form>
          <template #modal-footer>
            <div class="actionModel filtreprod">
              <b-button
                variant="success"
                @click="
                  setup(true);
                  hideModal('entrepriseModal');
                "
              >
                <div class="block-spinner">
                  {{ $t("Appliquer") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>
        <!-- --------------------------------------------------------------end filtre modale---------------------------------------------------------------- -->
      </div>
      <div class="filtre">
        <p class="table-count">{{ getTotalRowProduct }} {{ $t("PRODUCT") }}</p>
        <template v-if="currentcategorie">
          <div
            class="productTrie"
            v-for="(cat, i) in currentcategorie"
            :key="i"
          >
            {{ cat.lib }}
            <font-awesome-icon
              icon="times-circle"
              class="icons"
              @click="deleteCurrentCat(i)"
            />
          </div>
        </template>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead style="border-radius: 5px 0px 0px 0px">
            <tr>
              <th style="border-radius: 5px 0px 0px 0px">
                {{ $t("PRODUCT") }}
              </th>
              <th>{{ $t("NAME") }} {{ $t("PRODUCT") }}</th>
              <th>
                {{ $t("CATEGORY") }}
              </th>
              <th>
                {{ $t("MAKER") }}
              </th>
              <th>
                {{ $t("REFERENCE") }}
              </th>
              <th>
                {{ $t("PURCHASE") }}
              </th>
              <th>
                {{ $t("UNIT_PRICE") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("VAT") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("TYPE_UNITE") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllProducts"
              :key="i"
              :class="{ default_element: data.is_default }"
              @click.prevent.stop="handleUpdate(data)"
            >
              <td aria-colindex="1" role="cell">
                <div class="logotab" v-if="data.img">
                  <img
                    :src="`${ImageUrl}${data.img}`"
                    class="photo"
                    alt="logo"
                  />
                </div>
                <div class="Entrephoto" v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="94"
                    height="94"
                    viewBox="0 0 94 94"
                  >
                    <path
                      id="Icon_material-insert-photo"
                      data-name="Icon material-insert-photo"
                      d="M98.5,88.056V14.944A10.475,10.475,0,0,0,88.056,4.5H14.944A10.475,10.475,0,0,0,4.5,14.944V88.056A10.475,10.475,0,0,0,14.944,98.5H88.056A10.475,10.475,0,0,0,98.5,88.056ZM33.222,59.333,46.278,75.052,64.556,51.5l23.5,31.333H14.944Z"
                      transform="translate(-4.5 -4.5)"
                      fill="#dbdbdb"
                    />
                  </svg>
                </div>
              </td>
              <td aria-colindex="2" role="cell">
                <font-awesome-icon
                  icon="fa-solid fa-bookmark"
                  class="icons"
                  v-if="data.is_default"
                />
                {{ data ? data.lib : "" }}
              </td>
              <td
                aria-colindex="3"
                role="cell"
                v-if="
                  data.categorie_article && data.categorie_article.length <= 2
                "
              >
                <p v-for="(categorie, i) in data.categorie_article" :key="i">
                  {{ categorie ? categorie.lib : "" }}
                </p>
              </td>
              <td
                aria-colindex="3"
                role="cell"
                v-else-if="
                  data.categorie_article && data.categorie_article.length > 2
                "
              >
                <p
                  v-for="(categorie, i) in data.categorie_article.slice(0, 2)"
                  :key="i"
                >
                  {{ categorie ? categorie.lib : "" }}
                </p>
                <p style="font-weight: 800">
                  + {{ data.categorie_article.length - 2 }} {{ $t("MORE") }}
                </p>
              </td>
              <td aria-colindex="3" role="cell" v-else></td>
              <td aria-colindex="4" role="cell">
                {{ data.fabricant }}
              </td>
              <td aria-colindex="5" role="cell">
                {{ data.ref }}
              </td>
              <td aria-colindex="6" role="cell">
                {{ data.prix_achat | financial }}
              </td>
              <td aria-colindex="7" role="cell">
                {{ data.unit_price | financial }}
              </td>
              <td aria-colindex="8" role="cell">
                {{ data.tva }}
              </td>
              <td aria-colindex="8" role="cell" v-if="data.type_unite">
                {{ data.type_unite }}
              </td>
              <td aria-colindex="8" role="cell" v-else>-</td>
              <td
                aria-colindex="10"
                role="cell"
                class="action_col"
                v-if="isSuperAdmin || isentrepriseAdmin || isAssistant"
              >
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- --------------------------------------------------------Modification produit modal---------------------------------------------------------------------- -->
      <b-modal
        id="ModelConfigProduct"
        ref="ModelConfigProduct"
        scrollable
        @hidden="previewImage = null"
        @hide="v$.$reset()"
      >
        <template #modal-header>
          <h5>{{ $t("EDIT") }} {{ $t("PRODUCT") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form @submit.prevent="ProductModalNew" v-if="ProductToConfig">
          <div class="top">
            <div class="left">
              <div
                class="star"
                @click="ProductToConfig.favoris = !ProductToConfig.favoris"
              >
                <svg
                  v-if="!ProductToConfig.favoris"
                  xmlns="http://www.w3.org/2000/svg"
                  width="43.214"
                  height="38.942"
                  viewBox="0 0 43.214 38.942"
                >
                  <g
                    id="Icon_material-star"
                    data-name="Icon material-star"
                    transform="translate(-0.459 -0.543)"
                    fill="none"
                  >
                    <path
                      d="M22.066,30.691l11.783,6.764L30.722,24.707l10.41-8.578L27.423,15.023,22.066,3,16.708,15.023,3,16.129l10.41,8.578L10.283,37.455Z"
                      stroke="none"
                    />
                    <path
                      d="M 33.84827041625977 37.45508193969727 L 30.72150230407715 24.70670318603516 L 41.13136291503906 16.12920379638672 L 27.42314147949219 15.0230131149292 L 22.06568145751953 3.000002861022949 L 16.70823097229004 15.0230131149292 L 3.000001430511475 16.12920379638672 L 13.40986156463623 24.70670318603516 L 10.28309154510498 37.45508193969727 L 22.06568145751953 30.69101333618164 L 33.84827041625977 37.45508193969727 M 35.37580108642578 39.48506164550781 L 22.06568145751953 31.84407615661621 L 8.755561828613281 39.48506164550781 L 12.28895378112793 25.07883453369141 L 0.45876145362854 15.33102321624756 L 16.03633117675781 14.07398128509521 L 22.06568145751953 0.5431228876113892 L 28.09504127502441 14.07398128509521 L 43.67259979248047 15.33102321624756 L 31.84240913391113 25.07883453369141 L 35.37580108642578 39.48506164550781 Z"
                      stroke="none"
                      fill="#bebebe"
                    />
                  </g>
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="43.214"
                  height="38.942"
                  viewBox="0 0 43.214 38.942"
                >
                  <g
                    id="Icon_material-star"
                    data-name="Icon material-star"
                    transform="translate(-0.459 -0.543)"
                    fill="#FFC400"
                  >
                    <path
                      d="M22.066,30.691l11.783,6.764L30.722,24.707l10.41-8.578L27.423,15.023,22.066,3,16.708,15.023,3,16.129l10.41,8.578L10.283,37.455Z"
                      stroke="none"
                    />
                    <path
                      d="M 33.84827041625977 37.45508193969727 L 30.72150230407715 24.70670318603516 L 41.13136291503906 16.12920379638672 L 27.42314147949219 15.0230131149292 L 22.06568145751953 3.000002861022949 L 16.70823097229004 15.0230131149292 L 3.000001430511475 16.12920379638672 L 13.40986156463623 24.70670318603516 L 10.28309154510498 37.45508193969727 L 22.06568145751953 30.69101333618164 L 33.84827041625977 37.45508193969727 M 35.37580108642578 39.48506164550781 L 22.06568145751953 31.84407615661621 L 8.755561828613281 39.48506164550781 L 12.28895378112793 25.07883453369141 L 0.45876145362854 15.33102321624756 L 16.03633117675781 14.07398128509521 L 22.06568145751953 0.5431228876113892 L 28.09504127502441 14.07398128509521 L 43.67259979248047 15.33102321624756 L 31.84240913391113 25.07883453369141 L 35.37580108642578 39.48506164550781 Z"
                      stroke="none"
                      fill="#FFC400"
                    />
                  </g>
                </svg>
              </div>
              <div class="cam">
                <label for="file_input">
                  <svg
                    style="cursor: pointer"
                    id="Icon_material-photo-camera"
                    data-name="Icon material-photo-camera"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.456"
                    height="18.411"
                    viewBox="0 0 20.456 18.411"
                  >
                    <path
                      id="Tracé_2232"
                      data-name="Tracé 2232"
                      d="M19.746,16.473A3.273,3.273,0,1,1,16.473,13.2,3.273,3.273,0,0,1,19.746,16.473Z"
                      transform="translate(-6.245 -6.245)"
                      fill="#6b6b6b"
                    />
                    <path
                      id="Tracé_2233"
                      data-name="Tracé 2233"
                      d="M10.16,3,8.288,5.046H5.046A2.052,2.052,0,0,0,3,7.091V19.365a2.052,2.052,0,0,0,2.046,2.046H21.411a2.052,2.052,0,0,0,2.046-2.046V7.091a2.052,2.052,0,0,0-2.046-2.046H18.168L16.3,3Zm3.068,15.342a5.114,5.114,0,1,1,5.114-5.114A5.116,5.116,0,0,1,13.228,18.342Z"
                      transform="translate(-3 -3)"
                      fill="#6b6b6b"
                    />
                  </svg>
                </label>
                <b-form-file
                  v-model="ProductToConfig.image"
                  id="file_input"
                  style="display: none"
                  accept="image/*"
                  @change="chargeImg"
                >
                </b-form-file>
              </div>
              <svg
                v-if="!ProductToConfig.img"
                xmlns="http://www.w3.org/2000/svg"
                width="94"
                height="94"
                viewBox="0 0 94 94"
              >
                <path
                  id="Icon_material-insert-photo"
                  data-name="Icon material-insert-photo"
                  d="M98.5,88.056V14.944A10.475,10.475,0,0,0,88.056,4.5H14.944A10.475,10.475,0,0,0,4.5,14.944V88.056A10.475,10.475,0,0,0,14.944,98.5H88.056A10.475,10.475,0,0,0,98.5,88.056ZM33.222,59.333,46.278,75.052,64.556,51.5l23.5,31.333H14.944Z"
                  transform="translate(-4.5 -4.5)"
                  fill="#dbdbdb"
                />
              </svg>
              <div v-else>
                <!-- <img v-if="previewImage" :src="previewImage" /> -->
                <img
                  class="image produit"
                  v-if="previewImage"
                  :style="{ 'background-image': `url(${previewImage})` }"
                />
                <img
                  class="image produit"
                  :style="{
                    'background-image': `url(${ImageUrl}${ProductToConfig.img})`,
                  }"
                  v-else
                />
              </div>
            </div>
            <div class="right">
              <b-form-group :label="$t('prix_achat_ht')" label-for="prix_achat">
                <b-form-input
                  id="prix_achat"
                  type="number"
                  v-model="v$.ProductToConfig.prix_achat.$model"
                  :state="validateState('prix_achat')"
                  aria-describedby="prix_achat-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.ProductToConfig.prix_achat.$errors"
                  id="prix_achat-feedback"
                ></error-handle>
                <div v-if="erreurlist.prix_achat" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.prix_achat)">
                    <span
                      v-for="(e, index) in erreurlist.prix_achat"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.prix_achat }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('UNIT_PRICE')" label-for="prix_vente_HT">
                <b-form-input
                  id="prix_vente_HT"
                  type="number"
                  min="1"
                  v-model="v$.ProductToConfig.unit_price.$model"
                  :state="validateState('unit_price')"
                  aria-describedby="unit_price-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.ProductToConfig.unit_price.$errors"
                  id="unit_price-feedback"
                ></error-handle>
                <div v-if="erreurlist.unit_price" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.unit_price)">
                    <span
                      v-for="(e, index) in erreurlist.unit_price"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.unit_price }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('VAT')" label-for="tva">
                <b-form-select
                  v-model="v$.ProductToConfig.tva.$model"
                  :options="options"
                  :state="validateState('tva')"
                  aria-describedby="tva-feedback"
                ></b-form-select>
                <error-handle
                  :list="v$.ProductToConfig.tva.$errors"
                  id="tva-feedback"
                ></error-handle>
                <div v-if="erreurlist.tva" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.tva)">
                    <span v-for="(e, index) in erreurlist.tva" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.tva }}</span>
                </div>
              </b-form-group>
              <b-form-group style="margin-bottom: 0" :label="$t('TYPE_UNITE')">
                <b-form-select
                  v-model="ProductToConfig.type_unite"
                  :options="optionstype"
                  :state="validateState('type_unite')"
                  aria-describedby="type_unite-feedback"
                ></b-form-select>
                <div v-if="erreurlist.type_unite" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.type_unite)">
                    <span
                      v-for="(e, index) in erreurlist.type_unite"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.type_unite }}</span>
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="center">
            <b-form-group :label="$t('Nom produit')" label-for="lib">
              <b-form-input
                id="lib"
                v-model="v$.ProductToConfig.lib.$model"
                :state="validateState('lib')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="lib-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.ProductToConfig.lib.$errors"
                id="lib-feedback"
              ></error-handle>
              <div v-if="erreurlist.lib" class="error-message">
                <ul v-if="Array.isArray(erreurlist.lib)">
                  <span v-for="(e, index) in erreurlist.lib" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.lib }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('MAKER')" label-for="fabricant">
              <b-form-input
                id="fabricant"
                v-model="v$.ProductToConfig.fabricant.$model"
                :state="validateState('fabricant')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="fabricant-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.ProductToConfig.fabricant.$errors"
                id="fabricant-feedback"
              ></error-handle>
              <div v-if="erreurlist.fabricant" class="error-message">
                <ul v-if="Array.isArray(erreurlist.fabricant)">
                  <span v-for="(e, index) in erreurlist.fabricant" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.fabricant }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('REFERENCE')" label-for="reference">
              <b-form-input
                id="reference"
                v-model="v$.ProductToConfig.ref.$model"
                :state="validateState('ref')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="reference-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.ProductToConfig.ref.$errors"
                id="ref-feedback"
              ></error-handle>
              <div v-if="erreurlist.ref" class="error-message">
                <ul v-if="Array.isArray(erreurlist.ref)">
                  <span v-for="(e, index) in erreurlist.ref" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.ref }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('CATEGORIES')">
              <searchInput
                modalename="categorie"
                :list="computedAllArticle"
                :loader="getLoadingcategorieArticle"
                label="lib"
                :multiple="true"
                :selected="ProductToConfig.categorie_article"
                :limit="2"
                :searchFunc="allcategorieArticle"
                @searchfilter="selectCat($event)"
                :placeholder="$t('CATEGORIES')"
                :disabled="!isSuperAdmin"
              >
              </searchInput>
              <div v-if="erreurlist.categorie_article_id" class="error-message">
                <ul v-if="Array.isArray(erreurlist.categorie_article_id)">
                  <span
                    v-for="(e, index) in erreurlist.categorie_article_id"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.categorie_article_id }}</span>
              </div>
              <div
                v-if="v$.ProductToConfig.categorie_article.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>

            <b-form-group :label="$t('DESC')" label-for="Description">
              <b-form-textarea
                id="desc"
                v-model="ProductToConfig.desc"
                :placeholder="$t('Description')"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <div v-if="erreurlist.desc" class="error-message">
                <ul v-if="Array.isArray(erreurlist.desc)">
                  <span v-for="(e, index) in erreurlist.desc" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.desc }}</span>
              </div>
            </b-form-group>
          </div>
        </form>
        <template #modal-footer>
          <b-alert variant="warning" show v-if="errorU">
            {{ errorU }}
          </b-alert>
          <div class="double">
            <b-button
              variant="danger"
              class="succes-btn-modal btn-success"
              @click.prevent.stop="handleDelete(ProductToConfig)"
            >
              <div class="block-spinner">
                {{ $t("DELETE") }}
              </div>
            </b-button>
            <b-button
              variant="success"
              @click="ProductModalNew"
              class="succes-btn-modal btn-success"
              :disabled="getProductLoading"
            >
              <div class="block-spinner">
                {{ $t("EDIT") }}
                <div v-if="getProductLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <deleteModalVue
        :loader="getProductLoading"
        :elemToDelete="ProductToDelete"
        :elemDelete="delete_product"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowProduct"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowProduct"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <!-----------------------------------------------------------------------------------------------------pagiination------------------------------------------------------------ -->
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getProductLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/article"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import searchInput from "@/components/ui/searchInput";
import { useVuelidate } from "@vuelidate/core";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/articlemodale.vue";
import formatMoney from "accounting-js/lib/formatMoney.js";
import searchBar from "../components/ui/searchBar.vue";
import {
  required,
  maxLength,
  numeric,
  minValue,
  maxValue,
  requiredIf,
} from "@vuelidate/validators";
import errorHandle from "../components/ui/errorHandle.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    searchInput,
    deleteModalVue,
    storeModalVue,
    errorHandle,
    CommentMarche,
  },
  data() {
    return {
      prestations: [],
      search: "",
      bodyFormData: new FormData(),
      ProductToConfig: {
        lib: null,
        fabricant: null,
        ref: null,
        prix_achat: 1,
        unit_price: 1,
        img: null,
        tva: 0,
        desc: null,
        favoris: null,
        categorie: "TypePrestation.Fourniture",
        type_unite: "unitaire",
        categorie_article: null,
      },
      ProductToDelete: null,
      erreurlist: {
        lib: null,
        ref: null,
        desc: null,
        unit_price: null,
        tva: null,
        type_unite: null,
        entreprise_id: null,
        categorie_article_id: null,
        fabricant: null,
        prix_achat: null,
        favoris: null,
      },
      page: 1,
      perPage: 10,
      oldrefrence: null,
      options: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],
      optionstype: [
        { value: "m2", text: "m2" },
        { value: "ml", text: "ML" },
        { value: "h", text: "h" },
        { value: "forfait", text: this.$t("TypePrestation.Forfait") },
        { value: "unitaire", text: this.$t("unit_unitaire") },
      ],
      errorD: false,
      errorS: false,
      errorU: false,
      ImageUrl: domains.download,
      previewNewImage: null,
      previewImage: null,
      filtre_categorie: null,
      currentcategorie: null,
      optionDefault: [
        { value: null, text: "cacher articles publics" },
        { value: 1, text: "Voir articles publics" },
      ],
    };
  },
  validations() {
    return {
      ProductToConfig: {
        lib: { required, maxLength: maxLength(200) },
        fabricant: { maxLength: maxLength(100) },
        ref: { maxLength: maxLength(100) },
        type_unite: { required },
        prix_achat: {
          numeric,
          maxValue: maxValue(100000),
        },
        unit_price: {
          required,
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(100000),
        },
        tva: { required, numeric },
        categorie_article: { requiredIf: requiredIf(this.isSuperAdmin) },
      },
    };
  },
  methods: {
    ...mapActions([
      "edit_product",
      "all_products",
      "store_product",
      "delete_product",
      "allcategorieArticle",
      "all_entreprises",
      "visibilite_prestation",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.ProductToConfig[name];
      return $dirty ? !$error : null;
    },

    chargeNewImg(e) {
      const file = e.target.files[0];
      this.previewNewImage = URL.createObjectURL(file);
    },
    chargeImg(e) {
      const file = e.target.files[0];
      this.ProductToConfig.img = file;
      this.previewImage = URL.createObjectURL(file);
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },
    selectCategorie(query) {
      this.filtre_categorie = query;
    },

    deleteCurrentCat(i) {
      this.filtre_categorie.splice(i, 1);
      this.confirmsearsh();
    },

    confirmsearsh() {
      this.currentcategorie = this.filtre_categorie;
      this.fetchProducts(true);
      this.hideModal("entrepriseModal");
    },

    fetchProducts(refresh = false) {
      if (refresh) {
        this.page = 1;
      }
      const payload = {
        page: this.page,
        per_page: this.perPage,
      };
      if (this.search) {
        payload.search = this.search;
      }
      if (this.filtre_categorie) {
        payload.categorie_article_id = this.filtre_categorie.map(
          (item) => item.id
        );
      }
      this.all_products(payload);
    },

    resetModal() {
      this.previewNewImage = null;
      this.erreurlist = {
        lib: null,
        ref: null,
        desc: null,
        unit_price: null,
        tva: 0,
        type_unite: null,
        entreprise_id: null,
        categorie_article_id: null,
        fabricant: null,
        prix_achat: null,
        favoris: null,
      };
      this.errorU = null;
      this.$refs["ModelConfigProduct"].hide();
    },

    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleUpdate(data) {
      if (this.isSuperAdmin || this.isentrepriseAdmin || this.isAssistant) {
        this.ProductToConfig = { ...data };
        this.oldrefrence = data.reference;
        this.$refs["ModelConfigProduct"].show();
      }
    },

    handleDelete(PRO) {
      this.ProductToDelete = { ...PRO };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    selectCat(cat) {
      this.ProductToConfig.categorie_article = cat;
    },
    async ProductModalNew() {
      const isFormCorrect = await this.v$.ProductToConfig.$error;
      if (isFormCorrect) return;
      this.errorU = null;
      this.erreurlist = {
        lib: null,
        ref: null,
        desc: null,
        unit_price: null,
        tva: null,
        type_unite: null,
        entreprise_id: null,
        categorie_article_id: null,
        fabricant: null,
        prix_achat: null,
        favoris: null,
      };
      var bodyFormData = new FormData();
      bodyFormData.append("id", this.ProductToConfig.id);
      bodyFormData.append("lib", this.ProductToConfig.lib);
      bodyFormData.append("fabricant", this.ProductToConfig.fabricant);
      if (this.ProductToConfig.ref !== this.oldrefrence) {
        bodyFormData.append("ref", this.ProductToConfig.ref);
      }

      for (
        let index = 0;
        index < this.ProductToConfig.categorie_article.length;
        index++
      ) {
        bodyFormData.append(
          "categorie_article_id[" + index + "]",
          this.ProductToConfig.categorie_article[index].id
        );
      }

      bodyFormData.append(
        "prix_achat",
        this.ProductToConfig.prix_achat ? this.ProductToConfig.prix_achat : 0
      );
      bodyFormData.append(
        "unit_price",
        this.ProductToConfig.unit_price ? this.ProductToConfig.unit_price : 0
      );
      bodyFormData.append("tva", this.ProductToConfig.tva);
      bodyFormData.append("type_unite", this.ProductToConfig.type_unite);
      bodyFormData.append("desc", this.ProductToConfig.desc);
      if (this.ProductToConfig.image)
        bodyFormData.append("img", this.ProductToConfig.image);
      else bodyFormData.append("img", this.ProductToConfig.img);
      if (this.ProductToConfig.favoris) {
        bodyFormData.append("favoris", true);
      } else {
        bodyFormData.append("favoris", false);
      }
      bodyFormData.append("lang", "fr");

      await this.edit_product(bodyFormData)
        .then(() => {
          this.fetchProducts();
          this.hideModal("ModelConfigProduct");
          this.errorU = null;
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async ProductDelete() {
      await this.delete_product({
        id: this.ProductToDelete.id,
      })
        .then(() => {
          this.hideModal("ModelDeleteProduct");
          this.hideModal("ModelConfigProduct");
          this.errorD = null;
        })
        .catch((err) => {
          this.errorD = err;
        });
    },

    handleStore() {
      this.$root.$emit("bv::show::modal", "ProductModal");
    },

    Annulerfiltre() {
      this.filtre_categorie = this.currentcategorie;
      this.hideModal("entrepriseModal");
    },

    pagination(paginate) {
      this.page = paginate;
      this.fetchProducts();
    },

    setup(refresh = false) {
      this.search = null;
      this.currentcategorie = this.filtre_categorie;
      this.fetchProducts(refresh);
    },
  },

  mounted() {
    this.setup(true);
    if (this.isSuperAdmin || this.isCommercialBatigo) {
      this.all_entreprises({
        page: this.page,
        per_page: 999,
      });
    }
    if (this.getOnlineUser) {
      if (this.isSuperAdmin) {
        this.allcategorieArticle({
          page: this.page,
          per_page: 999,
        });
      } else {
        this.allcategorieArticle({
          page: this.page,
          per_page: 999,
        });
      }
    }
    if (!this.isSuperAdmin) this.visibilite_prestation();
  },

  computed: {
    ...mapGetters([
      "getAllProducts",
      "getAllentreprises",
      "getentrepriseLoading",
      "getAllcategorieArticle",
      "getProductLoading",
      "getTotalRowProduct",
      "getTabs",
      "getOnlineUser",
      "getLoadingcategorieArticle",
      "getAllprestationV",
    ]),
    computedAllArticle() {
      var list = [];
      if (!this.isSuperAdmin) {
        this.getAllcategorieArticle.forEach((cat) => {
          if (this.getAllprestationV.find((item) => item.id == cat.id))
            list.push(cat);
        });
      } else {
        list = this.getAllcategorieArticle;
      }
      return list;
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    computedAllentreprises() {
      return this.getAllentreprises;
      // var list = [
      //   {
      //     lib: "TOUTES LES ENTREPRISES",
      //     id: null,
      //   },
      // ];
      // if (this.getAllentreprises)
      //   this.getAllentreprises.forEach((element) => {
      //     if (element.id != 1) list.push(element);
      //   });
      // return list;
    },
  },

  filters: {
    financial: function (value) {
      return formatMoney(value, {
        symbol: "€",
        thousand: " ",
        precision: 2,
        decimal: ",",
        format: "%v %s",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.center-spin {
  position: absolute;
  bottom: 50%;
  right: calc(50% - 50px);
}
.image.produit {
  height: 175px;
  width: 175px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f5f6f8;
}

.input-group .icon-balance {
  top: 8px;
}
.imagePreviewWrapper {
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}
.fonticoneModifer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 0;
  width: 42px;
  height: 42px;
  background: #ecedef;
  border-radius: 50%;
}

.prodimg {
  @media only screen and (max-width: 900px) {
    width: auto;
    width: auto;
  }
  width: 198px;
  height: 168px;
  border-radius: 11%;
  margin: 35px 0 20px;
}

.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.body {
  padding: 4px 0;
  box-shadow: 1px 1px 8px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.detailClient {
  background-color: #f5f6f8;
  border-radius: 5px;

  .clientTop {
    display: flex;
    justify-content: space-between;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 1px 1px 24px #00000019;
      background-color: #fff;
      border-radius: 5px;
      text-align: center;
      position: relative;
      width: 55%;

      .star {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 24px;
        color: #ffc400;
      }
    }

    .right {
      padding: 8px;
      box-shadow: 1px 1px 24px #00000019;
      background-color: #fff;
      border-radius: 5px;
      width: 42%;
    }

    & .prodimg {
      @media only screen and (max-width: 900px) {
        max-width: 100px;
        max-height: 100px;
      }
      max-width: 185px;
      max-height: 231px;
    }
  }

  .clientMid {
    box-shadow: 1px 1px 24px #00000019;
    margin-top: 15px;
    border-radius: 5px;
    padding: 8px;
    background-color: #fff;
  }

  & h4 {
    color: #393939;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  & p {
    border: 1px solid #ced4da;
    border-radius: 5px;
    color: #393939;
    font-size: 14px;
    padding: 8px;
  }

  .desc {
    min-height: 120px;
    margin: 0;
  }
}

.filtre {
  display: flex;
  align-items: center;
}

.logotab {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  .photo {
    width: 100%;
    height: 100%;
  }
}
.Entrephoto {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  color: #fff;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filtreprod {
  display: flex;
  justify-content: center;
  width: 100%;
  button {
    margin-right: 8px;
  }
}
</style>
